@import '../../../variables.scss';

$icon-size: $spacer * 6;

.submit-page {
  .submit-page__icon {
    text-align: center;
    margin: $spacer * 2 0;
    i {
      margin: 0 auto;
      font-size: $icon-size / 2;
      background-color: $grey;
      color: white;
      width: $icon-size;
      height: $icon-size;
      line-height: $icon-size;
      border-radius: $icon-size / 2;
    }
  }

  .submit-page__buttons {
    margin-top: $spacer * 2;
  }

  .submit-page__text {
    margin-top: $spacer;
  }
}
