@import '../../../../node_modules/antwerp-core-react-branding/dist/styles/quarks';

.busy {
  margin-top: 45vh;
  text-align: center;
  width: 100vw;
  .a-spinner {
    &:before {
      color: $white !important;
    }
  }
}
