@import '../../../variables';

.locations {
  .locations__edit {
    .m-accordion__content {
      max-height: 0;
      transition: height 0.5s ease-out;
    }
    &.is-open {
      .m-accordion__content {
        max-height: 1500px;
      }
    }
  }

  .locations__intakes__intake--valid {
    color: $state-success;
    margin-right: 0.5rem;
  }

  @include to($screen-sm) {
    padding-right: 0 !important;
    padding-left: 0 !important;
    border-bottom: none;
    margin-bottom: -48px;
  }
}

.intakes-desktop {
  @include to($screen-sm) {
    display: none;
  }
}

.intakes-mobile {
  @include at($screen-sm) {
    display: none;
  }

  .m-card {
    margin: 0.5rem;
  }

  .m-card__body {
    padding: 0.5rem;
  }
  .row {
    height: 2rem;
    margin-bottom: 0.4rem;
    margin-right: 0.2rem;
    margin-top: 1rem;
    .col-xs {
      text-align: center;
    }
  }
}

.delete-location {
  top: -6px;
}

.edit-location {
  height: 1rem;
}
