.total-price {
  background-color: black;
  color: white;
}

.total-vat {
  background-color: black;
  color: white;
}

.align__left {
  text-align: left;
}
