@import '../../../variables.scss';

.messages {
  display: flex;
  flex-direction: column;
  align-content: space-around;

  &-item {
    background-color: #f5f5f5;
    margin: 0 0 20px 0;
    padding: 15px;
  }

  &-item:last-child {
    margin-bottom: 0;
  }

  &-content {
    padding: 15px 0 0 0;
    margin: 0;
    word-wrap: break-word;

    P {
      margin: 0;
      padding: 0;
    }
  }

  &-attachments {
    padding: 15px 0 0 0;

    &-file {
      display: inline-block;
      margin: 10px 10px 0 0;
      right: 10px;
      padding: 7px 15px;
      background: #b0bec5;
      border-radius: 20px;

      &:link,
      &:hover {
        text-decoration: unset;
        color: #ffffff;
      }

      &-new {
        background: #e4e4e4;
        &:link,
        &:hover {
          color: #2196f3;
        }
      }
    }

    &-spinner {
      display: inline;
      margin-left: 10px;

      &::before {
        font-size: 14px;
      }
    }

    &-delete {
      display: inline;
      margin-left: 10px;
    }

    &-progress {
      width: 22px !important;
      height: 22px !important;
      position: absolute;
      margin: -2px 0 0 4px;
    }
  }

  &-form {
    padding: 20px 0 0 0;

    &-input {
      padding: 15px;
      width: 100%;
      background-color: white;
      border: 1px solid #b0b0b0;
      color: #081f2c;
    }

    &-button {
      float: left;
      margin-top: 15px;
    }

    &-attachment {
      float: right;
      width: 36px;
      height: 36px;
      padding: 5px 7px 0 0;
      margin-left: 10px;
      cursor: pointer;

      &:hover {
        background: #e4e4e4;
      }

      &-icon {
        float: right;
        font-size: 25px;
        transform: rotate(225deg);
      }
    }
  }
  &-scroll {
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
  }
}
