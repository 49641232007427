@import '../../../variables.scss';
@import './leaflet.scss';

$map-height-editmap: 550px;
$map-height-editmap-sm: 250px;

.leaflet__container__wrapper {
  @include to($screen-sm) {
    width: 100%;
  }
}

.leaflet__container {
  @include to($screen-sm) {
    padding: 0;
    width: 100%;
  }
  .leaflet__editmap {
    margin-bottom: $spacer;
  }
}

.leaflet__editmap {
  @include leaflet-a-style($map-height-editmap);

  @include to($screen-sm) {
    @include leaflet-a-style($map-height-editmap-sm);
  }

  .leaflet__editmap__busy {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    .fa {
      color: white;
    }
  }
}

.leaflet__editmap__busy {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
  .fa {
    color: white;
  }
}

.leaflet__editmap--parkingbanintake,
.leaflet__editmap--minorconstructionsiteintake {
  .leaflet-marker-icon {
    border: none !important;
    margin-left: -15px !important;
    margin-top: -32px !important;
    width: 29px !important;
    height: 35px !important;
  }
  .leaflet-marker-icon[style*='opacity'] {
    display: none;
  }
}
