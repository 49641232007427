@import '../../../../variables';

$modal-width: 80vw;
$modal-width-sm: 90vw;
$modal-height-sm: 80vh;

.locationsformitem {
  .m-overlay__inner {
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacer * 4;
    width: $modal-width;

    @include to($screen-sm) {
      margin-top: $spacer * 2.5;
      width: $modal-width-sm;
      max-height: $modal-height-sm;
    }
  }

  .m-flyout {
    width: 100%;
  }

  .m-modal {
    max-width: $modal-width;
    padding: $spacer;

    @include to($screen-sm) {
      max-width: $modal-width-sm;
    }
  }

  .m-alert {
    max-height: none;
    max-width: none;
    margin: 0px;
  }

  .leaflet__container__wrapper {
    position: relative;

    .locationsformitem__refresh {
      left: calc($spacer * 4 / 3);
      position: absolute;
      top: $spacer;
      z-index: 1000;

      button {
        @include to($screen-sm) {
          font-size: 0;
          padding-left: 1.5rem;
        }
      }

      @include to($screen-sm) {
        left: calc($spacer / 2);
        top: calc($spacer / 2);
      }
    }
  }

  .row {
    @include to($screen-sm) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
