@import 'variables';

body {
  margin: 0;
}

.a-button:focus,
.a-button-transparent:focus,
.a-input INPUT:focus,
.a-input SELECT:focus,
.focusable:focus,
.a-button:focus,
.a-input__checkbox:focus {
  outline: $as-focussable-color solid 3px !important;
  z-index: 1;
}

.leaflet__map .a-button:focus {
  outline: none;
  background: $as-focussable-color !important;
}

.m-flyout__content {
  top: 3rem;
}

.m-nav-list > li > a:focus {
  background-color: #f3f3f3;
  color: #0064b4;
}

.has-error {
  color: #cd2026 !important;
  font-size: medium;
  font-weight: 500;
}

small,
.small {
  color: #414040 !important;
}

.fa-size-medium {
  font-size: 1.4em !important;
}

.a-input__checkbox input[type='checkbox']:focus + label:after,
.a-input__checkbox input[type='checkbox']:focus + label:before,
.o-header__logo.a:focus,
.u-text-readable > * a:focus,
.static_info_close:focus,
.static-info-link:focus,
.a-input__radio input[type='radio']:focus + label:before,
.o-header__logo.a:focus {
  outline: $as-focussable-color solid 3px !important;
}

.m-upload__dropzone:focus-within {
  background-color: #cce0f0;
}

.u-text-readable > * a {
  color: blue !important;
}

.u-text-readable > * a:visited {
  color: purple !important;
}

.u-text-light {
  color: $as-text-light-color !important;
}

.a-input__checkbox input:disabled + label,
.a-input__radio input:disabled + label {
  color: $as-text-light-color !important;
}

.required-star {
  color: $as-red;
}

legend {
  background: #d8d8d8 !important; /* TODO get hard coded colors from constant */
  color: black !important;
  font-weight: 600;
}

.m-selectable-list__item.hovered {
  background: $as-grey;
}

.a-input input:not([class*='a-button']):not([type='checkbox']):not([type='radio'])[disabled],
.a-input select[disabled],
.a-input textarea[disabled] {
  color: $as-text-light-color !important;
}

.leaflet-control-attribution {
  display: none !important;
}

.m-datepicker td button.is-disabled {
  background-image: url('/assets/images/circle-ban.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.m-step-indicator.wcag-compliance {
  li {
    &.is-completed {
      color: #006600;

      &:before {
        background-color: #006600;
        border-color: #006600;
      }

      &:after {
        background-color: #006600;
      }
    }
  }
}
