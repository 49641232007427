@import '../../../variables';

.formbuttons {
  .formbuttons__right {
    text-align: right;
    button {
      margin-left: calc($spacer / 2);
    }
  }
}
