@import '../../../variables.scss';

.workflow__stepper {
  padding-bottom: $spacer;

  @include to($screen-sm) {
    display: none;
  }

  hr {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
  }
}

.margin-top {
  @include to($screen-sm) {
    margin-top: $spacer * 2;
  }

  div > div > div.aui-hero-card > div > h3 {
    font-size: 1.95rem;
  }
}
