@import '../../../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss';
@import '../../../../node_modules/antwerp-core-react-branding/dist/styles/quarks';
@import '../../../variables.scss';

$card-height-lg: 18rem;
$card-margin-lg: 3%;
$card-width-lg: 30%;

$card-height-sm: 12rem;
$card-margin-sm: 5%;
$card-width-sm: 45%;

$card-height-xs: 5rem;
$card-margin-xs: 2%;
$card-width-xs: 100%;

@mixin cardselector--selected {
  line-height: 1.7;
  margin: 0.45rem auto;
}

.cardselector {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  .cardselector__item {
    align-items: flex-start;
    border-radius: 3%;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    min-height: $card-height-lg;
    justify-content: center;
    margin-left: $card-margin-lg;
    margin-top: $card-margin-lg;
    text-align: center;
    text-overflow: ellipsis;
    transition: background-color 0.2s ease-out, padding 0.2s ease-out;
    width: $card-width-lg;

    .m-card__body {
      padding: 1rem;
    }

    .cardselector__image {
      margin-bottom: calc($spacer / 2);

      img {
        height: $card-height-lg / 3.5;
      }
    }

    &[class*='--selected'] {
      @include m-alert;
    }

    @include breakpoint(max-width $screen-sm-min) {
      min-height: $card-height-sm;
      margin-left: $card-margin-sm;
      margin-top: $card-margin-sm;
      width: $card-width-sm;
      .m-card__body {
        padding: $spacer / 4;
        .cardselector__image {
          margin-bottom: $spacer/4;
          img {
            height: $card-height-sm / 3;
          }
        }
      }
    }

    @include breakpoint(max-width $screen-xs-min) {
      flex-flow: row;
      flex-flow: row;
      min-height: $card-height-xs;
      margin-left: 0;
      margin-top: $card-margin-xs;
      width: $card-width-xs;
      align-items: center;

      .m-card__body {
        padding: $spacer / 4;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        display: flex;
        flex-flow: row;
        p {
          text-align: left;
        }
        .cardselector__image {
          margin-bottom: 0;
          margin-right: $spacer/4;
        }
        .cardselector__remarks {
          display: none;
        }
      }
    }

    p {
      &.cardselector__title {
        text-transform: uppercase;
      }

      font-size: 1.3rem;
      @include breakpoint(max-width $screen-md-min) {
        font-size: 1.2rem;
      }

      @include breakpoint(max-width $screen-sm-min) {
        font-size: 1.1rem;
      }

      @include breakpoint(max-width $screen-xs-min) {
        font-size: 1rem;
      }
    }
  }
}

.cardselector__selected {
  .m-tag {
    max-width: 100%;
    display: inline-flex;
  }

  .m-tag__label {
    max-width: calc(100% - #{$spacer-md});

    span {
      text-overflow: ellipsis;
    }
  }
}

.cardselector__item:focus {
  outline: rgb(134, 211, 255) solid 3px !important;
}

.cardselector__wrapper {
  width: 100%;
}

.cardselector__tag {
  cursor: default !important;
}

.cardselector__tag:hover {
  background-color: #4aa32c !important;
}
