@import '../node_modules/antwerp-core-react-branding/dist/styles/_quarks.scss';

// Screen sizes
$screen-lg-min: 75em;
$screen-md-min: 64em;
$screen-sm-min: 48em;
$screen-xs-min: 30em;

// Asign colors
$as-text-light-color: #747373;
$as-focussable-color: rgb(134, 211, 255);
$as-blue: #2196f3;
$as-darkblue: rgb(28, 32, 182);
$as-darkgreen: rgb(3, 104, 25);
$as-darkgrey: rgb(33, 33, 33);
$as-darkpurple: rgb(130, 0, 130);
$as-darkred: rgb(141, 2, 35);
$as-green: rgb(76, 175, 80);
$as-grey: rgb(158, 158, 158);
$as-lightgreen: rgb(84, 233, 88);
$as-lightgrey: rgb(230, 230, 230);
$as-lightpurple: rgb(229, 1, 183);
$as-orange: rgb(255, 103, 37);
$as-pink: rgb(249, 170, 231);
$as-purple: rgb(177, 123, 245);
$as-red: rgb(244, 67, 54);
$as-teal: rgb(0, 150, 136);
$as-turquoise: rgb(65, 217, 227);
$as-yellow: rgb(255, 221, 31);
$as-yellowgreen: rgb(175, 211, 9);
$as-permit_granted: #036819;
$as-permit_ended: #6bb84b;
