@import '../../../../variables.scss';

.my-requests__extensions__header {
  margin-bottom: $spacer;

  h1 {
    display: inline-block;
    margin-bottom: calc($spacer / 2);
  }
}
