@import '../../../variables.scss';

.leaflet__editmap__zoomcontrol {
  right: 0;
  margin: $spacer;
  position: absolute;
  top: 0;
  z-index: 1000;

  @include to($screen-sm) {
    display: none;
  }
}
