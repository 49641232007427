@import '../../../variables.scss';

header {
  background-color: $nepal;
  height: 10vh;
  padding: $spacer/3;
  margin-bottom: $spacer * 5;

  .header__title {
    margin-left: auto;
    margin-right: auto;
  }

  &.o-header {
    position: absolute;
    z-index: 100000 !important;
    padding: 0;
    margin: 0;
    .o-header__logo {
      svg {
        height: $spacer * 4;
        width: $spacer * 4;
      }
    }

    @include to($screen-sm) {
      .aui-header__content-wrapper {
        flex-grow: 1;
        width: auto;
      }
      position: fixed !important;
      display: flex;
      .o-header__logo {
        position: initial;
        svg {
          height: $spacer * 2;
          width: $spacer * 2;
        }
      }
    }

    &.zoomed-in {
      position: absolute !important;
    }
  }
}

.aui-header {
  transition: transform 0.25s ease-in-out;
  will-change: transform;
  padding-bottom: 0;
  padding-top: 0;

  .aui-header__content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  .aui-header__content {
    flex: 1;
    height: 100%;
  }

  .aui-header__menu-items {
    display: flex;
    justify-content: flex-end;
  }

  .toggle {
    padding-right: 0;
  }
}
.o-header__logo:focus {
  box-shadow: 0 0 0 1px #005aa2 inset, 0 0 0 3px #bfd8ec;
}

.h3 {
  margin-bottom: 1.5rem;
}
