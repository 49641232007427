@import 'src/variables';

.state-label {
  display: inline-block;

  .state-label__badge {
    display: inline;
  }

  &.state-label--small {
    .state-label__badge {
      border-radius: rem($font-size-base) / 2;
      height: rem($font-size-base);
      margin-bottom: rem($font-size-base) / -9;
      margin-right: calc($spacer / 2);
      width: rem($font-size-base);
      display: inline-block;
    }
  }

  &.state-label--large {
    .state-label__badge {
      height: rem($font-size-base) * 1.5;
      padding-left: rem($font-size-base);
      padding-right: rem($font-size-base);
      border-radius: rem($font-size-base) * 1.5;
    }

    .state-label__label {
      color: $white;
      font-size: rem($font-size-base) * 0.9;
      line-height: rem($font-size-base) * 1.5;
    }
  }

  .state-label__label {
    display: inline-block;
  }

  .state-label__badge {
    &.state-label__badge--draft {
      background-color: $as-purple;
    }

    &.state-label__badge--waiting_for_payment {
      background-color: $as-darkpurple;
    }

    &.state-label__badge--submitted {
      background-color: $as-turquoise;
    }

    &.state-label__badge--reviewing {
      background-color: $as-orange;
    }

    &.state-label__badge--awaiting_feedback {
      background-color: $as-yellow;
    }

    &.state-label__badge--feedback_received {
      background-color: $as-yellow;
    }

    &.state-label__badge--approved {
      background-color: $as-darkgreen;
    }

    &.state-label__badge--rejected {
      background-color: $as-darkred;
    }

    &.state-label__badge--in_effect {
      background-color: $as-yellowgreen;
    }

    &.state-label__badge--planned {
      background-color: $as-lightpurple;
    }

    &.state-label__badge--deploying {
      background-color: $as-pink;
    }

    &.state-label__badge--deployed {
      background-color: $as-lightgreen;
    }

    &.state-label__badge--collecting {
      background-color: $as-darkblue;
    }

    &.state-label__badge--closed {
      background-color: $as-darkgrey;
    }

    &.state-label__badge--canceled {
      background-color: $as-lightgrey;
    }
    &.state-label__badge--unpaid {
      background-color: $as-blue;
    }
    &.state-label__badge--permit_granted {
      background-color: $as-permit_granted;
    }
    &.state-label__badge--permit_ended {
      background-color: $as-permit_ended;
    }
  }

  .a-label {
    &.a-label--draft {
      color: $as-purple;
      border-color: $as-purple;
    }

    &.a-label--waiting_for_payment {
      color: $as-darkpurple;
      border-color: $as-darkpurple;
    }

    &.a-label--submitted {
      color: $as-turquoise;
      border-color: $as-turquoise;
    }

    &.a-label--reviewing {
      color: $as-orange;
      border-color: $as-orange;
    }

    &.a-label--awaiting_feedback {
      color: $as-yellow;
      border-color: $as-yellow;
    }

    &.a-label--feedback_received {
      color: $as-yellow;
      border-color: $as-yellow;
    }

    &.a-label--approved {
      color: $as-darkgreen;
      border-color: $as-darkgreen;
    }

    &.a-label--rejected {
      color: $as-darkred;
      border-color: $as-darkred;
    }

    &.a-label--in_effect {
      color: $as-yellowgreen;
      border-color: $as-yellowgreen;
    }

    &.a-label--planned {
      color: $as-lightpurple;
      border-color: $as-lightpurple;
    }

    &.a-label--deploying {
      color: $as-pink;
      border-color: $as-pink;
    }

    &.a-label--deployed {
      color: $as-lightgreen;
      border-color: $as-lightgreen;
    }

    &.a-label--collecting {
      color: $as-darkblue;
      border-color: $as-darkblue;
    }

    &.a-label--closed {
      color: $as-darkgrey;
      border-color: $as-darkgrey;
    }

    &.a-label--canceled {
      color: $as-lightgrey;
      border-color: $as-lightgrey;
    }

    &.a-label--unpaid {
      color: $as-blue;
      border-color: $as-blue;
    }

    &.a-label--permit_granted {
      color: $as-permit_granted;
      background-color: $as-permit_granted;
    }
    &.a-label--permit_ended {
      color: $as-permit_ended;
      background-color: $as-permit_ended;
    }
  }
}
