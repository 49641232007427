@import '../../../variables.scss';

$state-timelineheight: 1.75rem;

.state-timeline__description {
  margin-top: $spacer;
}

.state-timeline {
  width: 100%;

  .state-timeline__item {
    border-right: 1px solid $grey;
    float: left;
    height: $state-timelineheight;
    line-height: $state-timelineheight;
    overflow: hidden;
    text-overflow: ellipsis;

    &.state-timeline__item--state {
      color: white;
    }

    span {
      font-size: 0.75rem;
      padding-left: calc($spacer / 2);
      text-overflow: ellipsis;
      text-transform: uppercase;
    }

    .state-timeline__item__label {
      color: white;
      font-weight: bold;
    }

    .state-timeline__item__date {
      display: inline-block;
      padding-left: 0;
      padding-right: calc($spacer / 4);
      text-align: right;
      width: 100%;
    }

    &.state-timeline__item--draft {
      background-color: $as-purple;
    }

    &.state-timeline__item--waiting_for_payment {
      background-color: $as-darkpurple;
    }

    &.state-timeline__item--submitted {
      background-color: $as-turquoise;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--reviewing {
      background-color: $as-orange;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--awaiting_feedback {
      background-color: $as-yellow;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--feedback_received {
      background-color: $as-yellow;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--approved {
      background-color: $as-darkgreen;
    }

    &.state-timeline__item--rejected {
      background-color: $as-darkred;
    }

    &.state-timeline__item--in_effect {
      background-color: $as-yellowgreen;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--planned {
      background-color: $as-lightpurple;
    }

    &.state-timeline__item--deploying {
      background-color: $as-pink;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--deployed {
      background-color: $as-lightgreen;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }

    &.state-timeline__item--collecting {
      background-color: $as-darkblue;
    }

    &.state-timeline__item--closed {
      background-color: $as-darkgrey;
    }

    &.state-timeline__item--canceled {
      background-color: $as-lightgrey;
      .state-timeline__item__label {
        color: $as-darkgrey;
      }
    }
  }
}
