@import '../../../variables.scss';
@import '../../../../node_modules/flexboxgrid/css/index.css';
@import '../../../../node_modules/antwerp-core-react-branding/dist/style.css';
@import '../../../../node_modules/breakpoint-sass/stylesheets/_breakpoint.scss';

body {
  min-height: 100%;
  padding-bottom: $spacer * 2;
  position: relative;
}

.layout {
  background-color: $white;
  width: 100%;
}

.content__wrapper {
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    padding-top: $spacer;

    @include breakpoint(max-width $screen-lg) {
      padding-right: $spacer;
      padding-left: $spacer;
      .formbuttons {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.static_info_overlay {
  z-index: 100001 !important;
}

.static_info_wrapper {
  z-index: 100002 !important;
}

.static-info-link {
  font-weight: inherit;
}

@media only screen and (max-width: 640px) {
  .fa-arrow-up {
    position: inherit !important;
    line-height: 1rem !important;
  }
}
