@import '../../../../node_modules/breakpoint-sass/stylesheets/breakpoint';
@import '../../../../node_modules/antwerp-core-react-branding/dist/styles/quarks';

.aui-hero {
  padding-top: $spacer * 4;

  @include to($screen-sm) {
    padding-top: $spacer;
  }
}

.small-subtitle {
  .aui-hero-card > div > h5 {
    font: 16px 'Sun Antwerpen';
    margin-bottom: 7px;
  }
}

.remove-aui-hero-line > div > div > div.aui-hero-cta {
  @include to($screen-sm) {
    display: none;
  }
}

.mobile-no-hero-items > div > div > div.aui-hero-cta {
  @include to($screen-sm) {
    display: none;
  }
}

.m-nav-tabs > li > a:focus {
  border-color: #0064b4;
}
