.a-timepicker {
  .a-input {
    margin-bottom: 1.5rem !important;
  }
}

a {
  cursor: pointer;
}

address > div:not(:last-child) {
  margin-bottom: 0.75rem !important;
}

.autoCompleteDescription {
  margin-bottom: 1.5rem;
  font-size: 14px;
  color: #081f2c;
  margin-top: -5px;
}

.hidden {
  position: absolute;
  left: -9999em;
}

.fieldset__no-bottom-border {
  border-bottom: none;
  padding-bottom: 0;
}

.a-input__wrapper:before {
  z-index: 2;
}
.replacecar-alert {
  padding: 1rem 0.5rem 0.5rem;
}
.emergency-request-alert {
  padding: 1rem 0.5rem 2rem;
}
