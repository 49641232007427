@mixin leaflet-a-style($map-height) {
  position: relative;
  .leaflet-container {
    height: $map-height;
    width: 100%;
  }
  .leaflet-control-layers-selector {
    &[type='radio'] {
      appearance: radio;
    }

    &[type='checkbox'] {
      appearance: checkbox;
    }
  }
  .leaflet-draw-edit-remove {
    display: none !important;
  }
}
