@import '../../../../variables.scss';

.my-requests__details__header {
  margin-bottom: $spacer;

  h1 {
    display: inline-block;
    margin-bottom: calc($spacer / 2);
  }
  .state-label {
    margin-left: calc($spacer / 2);
    vertical-align: top;
  }
}
