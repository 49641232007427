.static_info_overlay {
  background: #333;
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.static_info_wrapper {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.static_info_content {
  border-radius: 10px;
  background: #ffffff;
  color: #000;
  margin-top: 70px;
  padding: 30px;
  overflow-y: scroll;
  max-height: calc(100vh - 140px);
}

.static_info_content P,
.static_info_content UL {
  font-size: 16px;
  text-align: left;
}

.static_info_content h4 {
  margin-bottom: 2em;
}

.static_info_close {
  width: 30px;
  height: 30px;
  background-image: url('/assets/images/circle-cross.svg');
  background-size: 30px 30px;
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .static_info_content h4 {
    font-size: 16px;
  }

  .static_info_content P,
  .static_info_content UL {
    font-size: 14px;
  }
}

.wcag-compliance {
  h4 {
    text-align: left;
  }

  h5 {
    text-align: left;
    margin-top: 50px;
  }

  h6 {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .problem {
    h4 {
      text-align: inherit;
      font-size: medium;
      margin-bottom: 10px;
    }
    h5 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    h6 {
      margin-top: 25px;
      color: dimgray;
    }
    .subproblem {
      font-size: medium;
      color: dimgray;
    }

    summary {
      margin-left: -8px;
    }
  }

  .problem-text-view {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .wcag-compliance {
    h5,
    h6 {
      text-align: left;
      margin-top: 50px;
      font-size: medium;
    }
  }
}
